<template>
  <v-dialog
    v-if="value"
    @input="$emit('input', false)"
    persistent
    :value="value"
    max-width="528px"
  >
    <v-card>
      <v-progress-linear
        v-if="isSubmitting"
        indeterminate
      ></v-progress-linear>
      <v-card-title>
        {{ isEditing ? 'Edit' : 'Log new'}} entry
        <v-spacer />
        <v-btn icon @click="$emit('input', false)" color="grey darken-1">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form v-model="isObservationFormValid" ref="observationForm">
          <v-card>
            <v-card-subtitle class="pb-0">
              <div class="text-subtitle-1">Details</div>
            </v-card-subtitle>
            <v-container>
              <school-select
                v-if="floating"
                v-model="editingObservation.schoolId"
                :rules="[requiredRule]"
                :disabled="isEditing"
                :permission="'log entries'"
                class="mb-1" />
              <observation-category-select
                v-model="editingObservation.observationCategoryId"
                :rules="[requiredRule]"
                @input="
                  editingObservation.observationItemId = null;
                  refreshTags(editingObservation);
                  $forceUpdate();
                "
              ></observation-category-select>
              <observation-item-select
                v-model="editingObservation.observationItemId"
                @input="refreshTags(editingObservation)"
                class="mt-1 wrap-text-dropdown"
                :name="isEditing ? 'editObservation' : 'newObservation'"
                :observation-category-id.sync="editingObservation.observationCategoryId"
                :rules="[requiredRule, objectRule]"
                validate-on-blur
              ></observation-item-select>
              <v-radio-group
                v-model="editingObservation.observationType"
                class="mt-1 mb-2"
                dense
                hide-details
                :rules="[requiredRule]"
              >
                <template v-slot:label>
                  <div><b>Entry Type</b></div>
                </template>
                <v-radio label="Concern" value="concern"></v-radio>
                <v-radio label="Praise" value="praise"></v-radio>
                <v-radio label="Other" value="other"></v-radio>
              </v-radio-group>
              <observation-location-select
                v-model="editingObservation.location"
                ref="locationCombobx"
                :school-id="schoolId || editingObservation.schoolId"
                :name="isEditing ? 'editLocation' : 'newLocation'"
                header-text="Select an existing location or type a new one."
                class="mt-1"
                clearable
              ></observation-location-select>
              <v-textarea
                v-model="editingObservation.comment"
                :rows="3"
                auto-grow
                class="mt-1"
                hide-details="auto"
                hint="Describe your observation in sufficient detail that it can be addressed"
                label="Description"
                outlined
                dense
              ></v-textarea>
              <compressed-image-input
                v-model="editingObservation.pictureFiles"
                multiple
              ></compressed-image-input>
              <v-divider v-if="editingObservation.imagePaths && editingObservation.imagePaths.length" class="mb-2" />
              <div
                v-if="
                  editingObservation.imagePaths &&
                  editingObservation.imagePaths.length
                "
                style="
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;n
                "
              >
                <div
                  v-for="(
                    imagePath, imagePathIndex
                  ) in editingObservation.imagePaths"
                  :key="imagePath"
                  class="ma-1 d-flex flex-column space-between"
                  style="width: calc(50% - 16px); max-width: calc(50% - 16px);"
                >
                  <v-img v-if="imageUrls[imagePathIndex]" :src="imageUrls[imagePathIndex]"></v-img>
                  <v-skeleton-loader v-else type="image" style="width: 100px"></v-skeleton-loader>
                  <div class="flex-grow-1"></div>
                  <div style="display: flex; justify-content: center;">
                    <v-btn
                      @click="
                        editingObservation.imagePaths.splice(
                          imagePathIndex,
                          1
                        );
                        setImageUrls();
                      "
                      icon
                    >
                      <v-icon> mdi-delete </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
              <v-checkbox v-if="!isCustomizingQuantity && editingObservation.quantity && editingObservation.quantity.toString() === '1'" :value="isCustomizingQuantity || editingObservation.quantity !== 1" @change="isCustomizingQuantity = !isCustomizingQuantity" label="Log multiple instances of this entry" hide-details dense class="mb-1"></v-checkbox>
              <v-text-field
                v-show="isCustomizingQuantity || !editingObservation.quantity || editingObservation.quantity && editingObservation.quantity.toString() !== '1'"
                v-model="editingObservation.quantity"
                :rules="[requiredRule, integerRule, positiveRule, lessThan(20)]"
                class="mb-2 quantity-field"
                hide-details="auto"
                hint="If you need to log multiple instances of this entry, enter the quantity here."
                persistent-hint
                label="Quantity"
                type="number"
                outlined
                dense
              />
              <div v-if="(editingObservation.manualTags && editingObservation.manualTags.length) || isExplicitlyViewingTags" class="mb-1">
                <div>
                  <b>
                    Tags
                  </b>
                </div>
                <div class="mt-1 mb-1">
                  <v-chip
                    v-for="tag in editingObservation.tags"
                    :key="tag"
                    close
                    @click:close="removeEditTag(tag)"
                    class="tag"
                  >
                    {{tag}}
                  </v-chip>
                </div>
                <v-text-field
                  class="tag-input"
                  outlined
                  dense
                  hide-details
                  placeholder="Add new tag"
                  v-model="editTag"
                >
                  <template #append-outer>
                    <v-btn :color="editTag ? 'primary' : ''" fab small class="ma-0" @click="addEditTag" :disabled="!editTag">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </div>
              <v-btn
                v-else
                @click="isExplicitlyViewingTags = true"
                text
                color="primary"
              >
                <v-icon>mdi-plus</v-icon>
                Add tags <span v-if="editingObservation.tags.length" style="margin-left: 3px">({{editingObservation.tags.length}})</span>
              </v-btn>
            </v-container>
          </v-card>
          <v-card
            v-if="user.canAtSchool('propose tasks', editingObservation.schoolId) && !editingObservation.taskId && (!isEditing || !floating)"
            class="mt-2"
          >
            <v-card-subtitle class="pb-0">
              <div class="text-subtitle-1">Take action</div>
            </v-card-subtitle>
            <v-container>
              <v-checkbox
                v-model="editingObservation.shouldAssignAsTask"
                label="Create a task based on this entry"
                hide-details
                color="primary"
                class="ma-0"
              ></v-checkbox>
              <div
                v-if="editingObservation.shouldAssignAsTask"
                class="text-caption mt-1"
              >
                NOTE: This task will not be created until the SchoolDog Walk is
                completed.
              </div>

              <users-select
                v-if="editingObservation.shouldAssignAsTask"
                v-model="editingObservation.taskAssignUserId"
                :rules="[requiredRule]"
                dense
                label="User to propose Task to"
                persistent-hint
                show-self
                hide-details="auto"
                :valid-user-ids="getEligibleTaskAssigneesForTask({ schoolId: (schoolId || editingObservation.schoolId)}).map(user => user.value)"
                outlined
                class="mt-2" />
              <v-text-field
                v-if="editingObservation.shouldAssignAsTask"
                @input="editingObservation.taskTitle = $event"
                :value="
                  editingObservation.taskTitle ||
                  'Follow up on this entry'
                "
                class="mt-1"
                outlined
                dense
                label="Task Title"
                hide-details="auto"
              ></v-text-field>
              <v-textarea
                v-if="editingObservation.shouldAssignAsTask"
                @input="editingObservation.taskDescription = $event"
                :value="
                  editingObservation.taskDescription ||
                  'See the entry details for more information.'
                "
                class="mt-1"
                label="Task Description"
                hint="The Observation Details will automatically be included with the task."
                hide-details="auto"
                outlined
                dense
              ></v-textarea>
            </v-container>
          </v-card>
        </v-form>
        <div class="mt-2">
          <div v-if="preview">
            <b>Summary</b>
          </div>
          <div
            v-if="preview"
            class="text-center"
          >
            {{ preview }}
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close()" color="grey darken-1" text>
          Cancel
        </v-btn>
        <v-btn
          @click="save()"
          :disabled="isSubmitting"
          color="primary"
        >
          {{ isEditing ? 'Save Changes' : 'Submit' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import uuid from 'uuid'
import { mapGetters, mapState, mapActions } from 'vuex';
import {
  getDownloadURL,
  ref,
} from 'firebase/storage';

import CompressedImageInput from '@/components/common/CompressedImageInput.vue';
import ObservationCategorySelect from '@/components/walks/ObservationCategorySelect.vue';
import ObservationItemSelect from '@/components/walks/ObservationItemSelect.vue';
import ObservationLocationSelect from '@/components/walks/ObservationLocationSelect.vue';
import ImageUploadService from '@/services/ImageUploadService';
import UsersSelect from '../common/UsersSelect.vue';
import SchoolSelect from '../common/SchoolSelect.vue';

export default {
  name: 'LogEntryDialog',
  components: {
    CompressedImageInput,
    ObservationCategorySelect,
    ObservationLocationSelect,
    ObservationItemSelect,
    UsersSelect,
    SchoolSelect,
  },
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    value: Boolean,
    initialObservationCategoryId: String,
    initialObservation: {
      type: Object,
      default: () => ({
        comment: null,
        location: null,
        observationCategoryId: null,
        observationItemId: null,
        observationType: null,
        quantity: 1,
        pictureFiles: [],
        imagePaths: [],
        tags: [],
        manualTags: [],
      }),
    },
    floating: {
      type: Boolean,
      default: false,
    },
    schoolId: String,
    walkId: String, // only needed for file upload
    noView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editingObservation: null,
      imageUrls: [],
      isObservationFormValid: false,
      isSubmitting: false,
      isUsingTileView: true,
      objectRule: (value) =>
        (Boolean(value) && typeof value === 'object' && Boolean(value.value)) ||
        'Please choose from provided options',
      requiredRule: (value) => Boolean(value) || 'This field is required',
      integerRule: value => Number.parseInt(value).toString() == String(value) || 'This must be a whole number',
      positiveRule: value => Number.parseInt(value) > 0 || 'This must be greater than 0',
      lessThan: (max) => (value) => Number.parseInt(value) <= max || `This must be less than or equal to ${max}. If you need a higher number, please log multiple entries.`,
      schoolLocations: null,
      editTag: null,
      isExplicitlyViewingTags: false,
      floatingObservationId: this.getFirestoreId(),
      isCustomizingQuantity: false,
    };
  },
  computed: {
    ...mapGetters('app', [
      'schools',
      'getEligibleTaskAssigneesForTask',
      'getSchoolById',
    ]),
    ...mapState('app', [
      'isOffline',
      'observationSettings',
      'organizationSettings',
      'schoolLocationsById',
      'user',
      'users',
    ]),
    mappedSchoolLocations() {
      const schoolLocations = this.schoolLocationsById[this.schoolId || this.editingObservation.schoolId];
      if (!schoolLocations || !schoolLocations.usageMap) {
        return [];
      }
      const keys = Object.keys(schoolLocations.usageMap).filter(key => schoolLocations.usageMap[key] > 1);
      return keys.sort((a, b) => {
        return (
          schoolLocations.usageMap[b] - schoolLocations.usageMap[a]
        );
      });
    },
    observationCategoryById() {
      const map = {};
      this.observationCategories.forEach((observationCategory) => {
        map[observationCategory.id] = observationCategory;
      });
      return map;
    },
    observationCategories() {
      if (this.observationSettings) {
        return this.observationSettings.categories;
      }
      return [];
    },
    observationOptionsByCategoryId() {
      const map = {};
      this.observationCategories.forEach((category) => {
        map[category.id] = this.observationItemsByCategory[category.id]
          .map((observationItem) => ({
            text: observationItem.label,
            value: observationItem.id,
          }))
          .sort((a, b) => {
            if (a.text === 'Other' && b.text === 'Other') return 0;
            if (a.text === 'Other') return 1;
            if (b.text === 'Other') return -1;
            return a.text.localeCompare(b.text);
          });
      });
      return map;
    },
    observationOptionsWithCategories() {
      const options = [];
      this.observationItems.forEach((observationItem) => {
        observationItem.categories.forEach((categoryId) => {
          options.push({
            text: `${observationItem.label} (${this.observationCategoryById[categoryId].label})`,
            value: `${categoryId}__${observationItem.id}`,
          });
        });
      });
      return options.sort((a, b) => {
        if (a.text === 'Other' && b.text === 'Other') return 0;
        if (a.text === 'Other') return 1;
        if (b.text === 'Other') return -1;
        return a.text.localeCompare(b.text);
      });
    },
    observationItems() {
      if (this.observationSettings) {
        return this.observationSettings.items;
      }
      return [];
    },
    observationItemById() {
      const map = {};
      this.observationItems.forEach((observationItem) => {
        map[observationItem.id] = observationItem;
      });
      return map;
    },
    observationItemsByCategory() {
      const map = {};
      if (!this.observationSettings) {
        return map;
      }
      this.observationSettings.items.forEach((observationItem) => {
        observationItem.categories.forEach((categoryId) => {
          if (!map[categoryId]) {
            map[categoryId] = [];
          }
          map[categoryId].push(observationItem);
        });
      });
      return map;
    },
    preview () {
      const observation = this.editingObservation
      if (
        !(
          observation.observationCategoryId
        ) ||
        !observation.observationItemId
      ) {
        return '';
      }
      let preview = '"';
      const category =
        this.observationCategoryById[
          observation.observationCategoryId
        ];
      const item = this.observationItemById[observation.observationItemId];
      if (observation.observationType === 'concern') {
        preview += 'I have a concern relating to ';
      } else if (observation.observationType === 'praise') {
        preview += 'I want to give praise relating to ';
      } else {
        preview += 'I want to make an observation relating to ';
      }
      preview += category.label + ' (' + item.label + ')';
      if (observation.comment) {
        preview += '. I noticed the following';
        if (observation.location) {
          preview += ' in (the) ' + observation.location;
        }
        preview += ': ' + observation.comment;
      } else if (observation.location) {
        preview += ' in (the) ' + observation.location;
      }
      return preview + '"';
    },
  },
  methods: {
    ...mapActions('app', [
      'createModel',
      'showSuccess',
    ]),
    close() {
      this.$emit('input', false)
      this.editingObservation = null
    },
    async save() {
      if (this.isSubmitting) {
        return;
      }

      this.$refs.locationCombobx.blur();
      await this.$nextTick();
      await this.$refs.observationForm.validate();
      if (!this.isObservationFormValid) {
        return;
      }

      this.isSubmitting = true;

      if (this.editingObservation.pictureFiles) {
        const newPictures = await this.uploadPictures(this.editingObservation);
        this.editingObservation.imagePaths =
        [
          ...(this.editingObservation.imagePaths || []),
          ...newPictures,
        ]
      }
      delete this.editingObservation.pictureFiles;

      if (this.floating && !this.isEditing) {
        const searchableLocation = this.editingObservation.location ? this.editingObservation.location.trim().toLowerCase() : '';
        const searchableTags = (this.editingObservation.tags || []).map(tag => tag.trim().toLowerCase());
        let walkType = 'partner';
        if (this.user.isDistrictUser()) {
          walkType = 'district'
        } else if (this.user.roleSchoolIds.includes(this.editingObservation.schoolId)) {
          walkType = 'school'
        }

        const observationFields = {
          ...this.editingObservation,
          id: this.floatingObservationId,
          walkType,
          walkId: null,
          searchableLocation,
          searchableTags,
          timestamp: new Date(),
          reportedByUserId: this.user.id,
          id: this.getFirestoreId(),
        };
        const observation = await this.createModel({
          model: 'Observation',
          id: this.floatingObservationId,
          data: observationFields,
        });
        if (this.isOffline) {
          observation.save(true);
        } else {
          await observation.save(true);
        }

        const observationDelta =  await this.createModel({
          model: 'ObservationDelta',
          id: this.floatingObservationId,
          data: {
            createdAtUrl: window.location.href,
            observationId: this.floatingObservationId,
            schoolId: observationFields.schoolId,
            entrySource: observationFields.walkType,
            deltaFields: {
              from: null,
              to: { observationFields },
            },
            type: 'create',
            reportedByUserId: this.user.id,
          },
        });
        if (this.isOffline) {
          observationDelta.save(true);
        } else {
          await observationDelta.save(true);
        }

        // Don't bother caching common locations while offline, inefficient use of resources
        if (!this.isOffline && observation.location) {
          if (!this.schoolLocationsById[observation.schoolId].usageMap[observation.location]) {
            this.$set(this.schoolLocationsById[observation.schoolId].usageMap, observation.location, 0);
          }
          this.$set(this.schoolLocationsById[observation.schoolId].usageMap, observation.location, this.schoolLocationsById[observation.schoolId].usageMap[observation.location] + 1);
          this.schoolLocationsById[observation.schoolId].save();
        }

        this.showSuccess({
          message: 'You just logged an entry!',
          buttonText: this.noView ? null : 'View',
          action: () => {
            this.$router.push(`/entries/${observation.id}`);
          },
        })

        // show pendo NPS
        if (pendo) {
          
        }
      } else if (this.floating) {
        const deltaFields = {
          to: {},
          from: {},
        };
        Object.keys(this.editingObservation).forEach((key) => {
          if (this.isOffline) {
            deltaFields.to[key] = this.editingObservation[key];
            deltaFields.from[key] = this.initialObservation[key];
            return;
          }
          if (Array.isArray(this.editingObservation[key]) || typeof this.editingObservation[key] === 'object') {
            if (JSON.stringify(this.initialObservation[key]) != JSON.stringify(this.editingObservation[key])) {
              deltaFields.to[key] = this.editingObservation[key];
              deltaFields.from[key] = this.initialObservation[key];
            }
          } else if (this.initialObservation[key] != this.editingObservation[key]) {
            deltaFields.to[key] = this.editingObservation[key];
            deltaFields.from[key] = this.initialObservation[key];
          }
        });
        if (!Object.keys(deltaFields).length) {
          this.$emit('input', false)
          this.isSubmitting = false;
          return;
        }
        if (this.isOffline) {
          this.FirestoreService.saveDocument('observations', this.initialObservation.id, deltaFields.to);
        } else {
          await this.FirestoreService.saveDocument('observations', this.initialObservation.id, deltaFields.to);
        }

        const observationDelta = await this.createModel({
          model: 'ObservationDelta',
          id: this.getFirestoreId(),
          data: {
            createdAtUrl: window.location.href,
            observationId: this.initialObservation.id,
            schoolId: this.initialObservation.schoolId,
            deltaFields,
            type: 'update',
            entrySource: this.initialObservation.walkType,
            reportedByUserId: this.initialObservation.reportedByUserId || this.user.id,
          },
        });
        if (this.isOffline) {
          observationDelta.save(true);
        } else {
          await observationDelta.save(true);
        }

        this.showSuccess({
          message: 'Entry updated successfully',
          buttonText: this.noView ? null : 'View',
          action: () => {
            this.$router.push(`/entries/${this.initialObservation.id}`);
          },
        })
      }
      this.$emit('save', this.editingObservation);

      this.$emit('input', false)
      this.isSubmitting = false;
    },
    async uploadPictures() {
      if (!this.editingObservation.pictureFiles) {
        return [];
      }

      const imagePaths = [];

      this.editingObservation.pictureFiles.forEach((file) => {
        const storageRef = ImageUploadService.beginFileUpload(this.getNewPicturePath(), file);
        imagePaths.push(storageRef.fullPath);
      });


      return imagePaths;
    },
    getNewPicturePath() {
      if (this.floating) {
        return `observations/${this.floatingObservationId}/${uuid()}`;
      }
      return `walks/${this.walkId}/${uuid()}`;
    },
    refreshTags(observation) {
      const newTags = [];
      if (observation.observationCategoryId) {
        const category = this.observationCategoryById[observation.observationCategoryId];
        const categoryTags = category.tags ?? [];
        categoryTags.forEach((tag) => {
          newTags.push(tag);
        })
      }
      if (observation.observationItemId) {
        const item = this.observationItemById[observation.observationItemId];
        const itemTags = item.tags ?? [];
        itemTags.forEach((tag) => {
          newTags.push(tag);
        })
      }
      if (observation.manualTags) {
        observation.manualTags.forEach((tag) => {
          newTags.push(tag);
        })
      }
      observation.tags = [
        ...new Set(newTags),
      ];
    },
    setObservationItemId(observationItem) {
      const observationItemId = observationItem.value;
      if (observationItemId && observationItemId.includes('__')) {
        const parts = observationItemId.split('__');
        this.editingObservation.observationCategoryId = parts[0];
        this.editingObservation.observationItemId = parts[1];
      } else {
        this.editingObservation.observationItemId = observationItemId;
      }
      this.refreshTags(this.editingObservation);
      this.$forceUpdate();
    },
    async setImageUrls () {
      this.imageUrls = [];
      if (this.editingObservation.imagePaths && this.editingObservation.imagePaths.length) {
        const imageUrls = await Promise.all(this.editingObservation.imagePaths.map(async (imagePath) => {
          try {
            const imageRef = ref(this.storage, imagePath);
            return await getDownloadURL(imageRef);
          } catch (e) {
            console.error(e);
            return null;
          }
        }))
        this.imageUrls = imageUrls.filter(url => url);
      }
    },
    addEditTag() {
      // don't add null tag
      if (this.editTag == null) {
        return;
      }
      // strip whitespace
      this.editTag = this.editTag.trim();
      // don't add empty tag
      if (this.editTag == '') {
        return;
      }
      if (this.editingObservation.tags.indexOf(this.editTag) < 0) {
        this.editingObservation.tags.push(this.editTag);
      }
      if (this.editingObservation.manualTags.indexOf(this.editTag) < 0) {
        this.editingObservation.manualTags.push(this.editTag);
      }
      this.editTag = null;
    },
    removeEditTag(removeTag) {
      this.editingObservation.tags = this.editingObservation.tags.filter((tag) => tag != removeTag)
    },
    getFirestoreId() {
      // get random 20 character string with upper and lowercase numbers and letters
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let id = '';
      for (let i = 0; i < 20; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
  },
  watch: {
    value: {
      handler () {
        if (this.value) {
          this.editingObservation = JSON.parse(JSON.stringify(this.initialObservation));
          this.isExplicitlyViewingTags = false
          if (this.initialObservationCategoryId) {
            this.editingObservation.observationCategoryId = this.initialObservationCategoryId;
            this.refreshTags(this.editingObservation);
          }
          if (this.isEditing) {
            this.setImageUrls();
          }
          if (this.floating) {
            this.floatingObservationId = this.getFirestoreId();
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  ::v-deep .combobox-container {
    position: relative;
    .v-menu__content {
      top: 40px !important;
    }
  }
}

::v-deep .quantity-field .v-messages__message {
  line-height: 14px;
}
</style>
