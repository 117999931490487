export default {
  // main navigation - side menu
  menu: [
    {
      text: '',
      key: '',
      isDashboard: true,
      items: [
        { icon: 'mdi-view-dashboard-outline', key: 'menu.dashboard', text: 'Dashboard', link: '/dashboard', permissions: { canAtAnySchool: 'log entries|manage entries|manage other entries|view entry leaderboards|propose tasks|view data insights' }, worksOffline: true },
      ],
    },
    {
      text: 'View Data',
      permissions: {
        canAtAnySchool: 'log entries|manage entries|manage other entries|propose tasks|be proposed tasks|record drills',
        or: true,
      },
      items: [
        /**
         *           menu[0].items.push({
            icon: 'mdi-clipboard-text',
            text: 'My Tasks',
            link: '/tasks',
          })
        }
         */
        { icon: 'mdi-pencil-box-multiple', text: 'Entries', link: '/entries', permissions: { canAtAnySchool: 'log entries|manage entries|manage other entries'}, worksOffline: true },
        { icon: 'mdi-walk', key: 'menu.schoolDogWalks', text: 'SchoolDog Walks', link: '/walks', worksOffline: true, permissions: { canAtAnySchool: 'log entries|manage entries|manage other entries'} },
        {
          icon: 'mdi-clipboard-text',
          text: 'Tasks',
          link: '/tasks',
          permissions: { canAtAnySchool: 'propose tasks|be proposed tasks' },
        },
        // { icon: 'mdi-format-list-numbered', text: 'Completed Rubrics', link: '/rubrics', permissions: { can: 'manage rubrics' } },
        {
          icon: 'mdi-whistle',
          text: 'Drills',
          link: '/drills',
          permissions: { canAtAnySchool: 'record drills' },
          worksOffline: true,
        },
      ],
    },
    {
      text: 'Analyze Data',
      permissions: {
        canAtAnySchool: 'view data insights',
        can: 'view data insights|manage cycles|download administrative reports',
        or: true,
      },
      items: [
        {
          icon: 'mdi-file-chart',
          text: 'Administrative Reports', link: '/reports', permissions: { can: 'download administrative reports' },
        },
        { icon: 'mdi-sync', key: 'menu.cycles', text: 'Cycles', link: '/cycles', permissions: { can: 'manage cycles' } },
        { text: 'Data Insights', link: '/insights', icon: 'mdi-chart-line', permissions: { canAtAnySchool: 'view data insights' }  },
      ],
    },
    {
      text: 'Configure',
      permissions: {
        can: 'manage users',
        canAtAnySchool: 'manage rubrics',
        or: true,
      },
      items: [
        { icon: 'mdi-account-group', key: 'menu.admin.manageUsers', text: 'Manage Users', link: '/admin/manageUsers', permissions: { can: 'manage users' } },
        { icon: 'mdi-format-list-numbered', key: 'menu.rubrics', text: 'Rating Rubrics', link: '/rubrics', permissions: { can: 'manage rubrics' } },
      ],
    },
    {
      text: 'System',
      items: [],
    },
    {
      text: 'Resources',
      items: [
        { icon: 'mdi-link-box', key: 'menu.resourceLinks', text: 'Resource Links', link: '/resources/links', worksOffline: true },
        { icon: 'mdi-help-circle-outline', text: 'Get Support', link: '/faq', worksOffline: true },
      ],
    },
    {
      text: 'SchoolDog Staff Settings',
      requireSchoolDogStaff: true,
      items: [
        { icon: 'mdi-security', key: 'menu.admin.schooldog', text: 'Dev Settings', link: '/admin/schooldog', requireSchoolDogStaff: true },
        { icon: 'mdi-tune-vertical-variant', text: 'Observation Settings', link: '/walkSettings', requireSchoolDogStaff: true },
      ],
    },
    // {
    //   text: 'Get Help',
    //   key: 'menu.getHelp',
    //   items: [
    //     { icon: 'mdi-face-agent', key: 'menu.contactSupport', text: 'Contact Support', link: '/support' },
    //     { icon: 'mdi-help-circle-outline', key: 'menu.frequentlyAskedQuestions', text: 'Frequently Asked Questions', link: '/faq' },
    //   ],
    // },
    {
      text: 'Legal',
      key: 'menu.legal',
      items: [
        { icon: 'mdi-file-document-outline', key: 'menu.legalSection.termsOfUse', text: 'Terms and Conditions', link: '/terms', worksOffline: true },
        { icon: 'mdi-file-eye-outline', key: 'menu.legalSection.privacyPolicy', text: 'Privacy Policy', link: '/privacy', worksOffline: true },
      ],
    },
  ],

  // footer links
  footer: [],
}
