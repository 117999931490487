import FirestoreModel from './FirestoreModel';

class Walk extends FirestoreModel {
    static collectionName = 'walks';
    static allowedFields = [
      'archivedBy',
      'awaitingReviewBy',
      'collaborators',
      'createdByUserId',
      'currentStage',
      'context.schoolComment',
      'context.weatherPrecipitation',
      'context.weatherTemperature',
      'context.weatherComment',
      'context.additionalNotes',
      'hasSentNeighborhoodWatch',
      'intendedCategories',
      'isDeleted',
      'leaderUserId',
      'observations',
      'rubrics',
      'schoolId',
      'schoolKey',
      'score',
      'observationsById',
      'shouldSendNeighborhoodWatch',
      'status',
      'submissionHistory',
      'timeframe',
      'timeArchived',
      'timeCompleted',
      'walkType',
    ]

    constructor(id, data) {
      super(id, data);
      if (id && this.createdByUserId && !this.leaderUserId) {
        this.leaderUserId = this.createdByUserId;
        this.save()
      }
      if (!this.rubrics) {
        this.rubrics = [];
      }
      if (!this.observationsById) {
        this.observationsById = {};
      }

      return this;
    }

    getDataToSave() {
      // run super method to get data
      const dataToSave = super.getDataToSave();

      if (dataToSave.observationsById) {
        // Need to make sure we don't overwrite other keys on the `observationsById` object
        for (const [
          id,
          observation,
        ] of Object.entries(dataToSave.observationsById)) {
          dataToSave[`observationsById.${id}`] = observation;
        }
        delete dataToSave.observationsById;
      }
      return dataToSave;
    }
}

export default Walk;
